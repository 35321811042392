import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
} from "antd";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadProduct } from "../../redux/rtk/features/product/productSlice";
import { loadSuppliers } from "../../redux/rtk/features/supplier/supplierSlice";
import Products from "./Products";
import { fetchWarehouses } from "../../redux/rtk/features/warehouse/warehouseSlice";
import { fetchCompanies } from "../../redux/rtk/features/company/companySlice";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import BigDrawer from "../Drawer/BigDrawer";
import AddSup from "../suppliers/addSup";
import { addPurchase } from "./../../redux/rtk/features/purchase/purchaseSlice";
import { loadAllVatTax } from "../../redux/rtk/features/vatTax/vatTaxSlice";

const { Title } = Typography;

const AddPurch = () => {
  const { Option } = Select;
  const [loader, setLoader] = useState(false);
  const [subTotal, setSubTotal] = useState([]);
  const [total, setTotal] = useState(0);
  const [showTotal, setShowTotal] = useState(0);
  const [afterVatTaxAdded, setAfterVatTaxAdded] = useState(0);
  const [sTaxes, setSTaxes] = useState(0);
  const [totalTaxo, setTotalTaxo] = useState(0);
  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day"); 
  };
  // console.log(afterVatTaxAdded);
  // const [transportCharge, setTransportCharge] = useState(0);
  // console.log(total);
  const [afterDiscount, setAfterDiscount] = useState(0);
  const [due, setDue] = useState(0);
  const { list: vatTaxList, loading: vatTaxLoading } = useSelector(
    (state) => state.vatTax
  );

  const onClickLoading = () => {
    setLoader(true);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSuppliers({ status: true, page: 1, count: 10 }));
    // dispatch(loadProduct({ status: true, page: 1, count: 10 }));
    dispatch(loadProduct({ query: "all" }));
    dispatch(fetchWarehouses({ status: true, page: 1, count: 10 }));
    dispatch(fetchCompanies({ status: true, page: 1, count: 10 }));
    dispatch(loadAllVatTax());
  }, [dispatch]);

  const allSuppliers = useSelector((state) => state.suppliers.list);
  const allWarehouses = useSelector((state) => state.warehouse.list);
  const allCompanies = useSelector((state) => state.company.list);
  // console.log(allWarehouses);
  // console.log(allCompanies);
  const { list: productList, loading: productLoading } = useSelector(
    (state) => state.products
  );

  // Form Function
  const [form] = Form.useForm();
  useEffect(() => {
    totalCalculator();
  }, []);

  const onFormSubmit = async (values) => {
    // console.log("Val", values);
    try {
      const mergedObject = values.purchaseInvoiceProduct.reduce(
        (accumulator, currentObject) => {
          const productId = currentObject.productId;
          if (!accumulator[productId]) {
            accumulator[productId] = { ...currentObject };
          } else {
            accumulator[productId].productQuantity +=
              currentObject.productQuantity;
          }
          return accumulator;
        },
        {}
      );

      const ftotal = due;
      const mergedArray = Object.values(mergedObject);
      const totalWithTransportCharge =
        parseFloat(values.transportCharge) +
        parseFloat(
          mergedArray.reduce(
            (total, current) =>
              total +
              (current.productPurchasePrice || 0) * current.productQuantity,
            0
          )
        );
      const selectedTaxIds = form.getFieldValue("vatId") || [];
      const selectedTaxes = vatTaxList
        ? vatTaxList.filter((tax) => selectedTaxIds.includes(tax.id))
        : [];
      const taxp = selectedTaxes;
      // console.log(taxp);

      const transportCharge = parseFloat(values.transportCharge) || 0;
      const data = {
        ...values,
        purchaseInvoiceProduct: mergedArray,
        warehouseId: values.warehouseId,
        companyId: values.companyId,
        ewayBill: values.ewayBill,
        transportCharge: transportCharge,
        totalAmount: total,
        // productVat: selectedTaxes,
      };
      // console.log("Data to be sent:", data);
      const resp = await dispatch(addPurchase(data));
      // console.log("", resp);
      if (resp.payload.message === "success") {
        form.resetFields();
        setLoader(false);
        navigate(`/admin/purchase/${resp.payload.createdInvoiceId}`);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error("Error Submitting form:", error);
      setLoader(false);
    }
  };

  const transportChargeV =
    parseFloat(form.getFieldValue("transportCharge")) || 0;

  // validation handlers
  const validatePaidAmount = (_, value) => {
    if (value >= 0 && value <= afterVatTaxAdded + transportChargeV) {
      return Promise.resolve();
    }
    return Promise.reject(
      `Paid Amount Must Be Less Than or Equal ${
        afterVatTaxAdded + transportChargeV
      }`
    );
  };
  const validateDiscount = (_, value) => {
    if (value >= 0 && value <= total) {
      return Promise.resolve();
    }
    return Promise.reject(`Discount Must Be Less Than or Equal ${total}`);
  };

  const totalCalculator = () => {
    // Calculate taxes only if vatTaxList is not null
    const selectedTaxIds = form.getFieldValue("vatId") || [];
    const selectedTaxes = vatTaxList
      ? vatTaxList.filter((tax) => selectedTaxIds.includes(tax.id))
      : [];
    // console.log(selectedTaxes);

    const productArray = form.getFieldValue("purchaseInvoiceProduct");

    let fpriceTotal = 0;
    let mPrice = 0;
    let mVat = 0;
    // let fpriceTax = 0;
    let totalTax = 0;

    const subTotal =
      productArray?.reduce((subTotal, current) => {
        const quantity = current?.productQuantity || 0;
        const price = current?.productPurchasePrice || 0;
        const vat = current?.productVat || 0;
        const fprice = price * quantity;
        const ptax = (fprice * vat) / 100;
        mPrice = mPrice + fprice;
        mVat = mVat + vat;
        totalTax += ptax || 0;
        // console.log(totalTax);
        // fpriceTotal += fprice;
        // fpriceTotal += ptax;
        return [...subTotal, fprice + ptax];
      }, []) || [];

    const toShow = totalTax || 0;
    setTotalTaxo(toShow);
    console.log(subTotal);

    // console.log("Total: ", fpriceTotal);
    // console.log("Selected Taxes: ", selectedTaxes);

    const transportCharge =
      parseFloat(form.getFieldValue("transportCharge")) || 0;

    const totalWithoutTransport =
      subTotal.reduce((total, current) => total + current, 0) || 0;

    const total = totalWithoutTransport + transportCharge || 0;
    console.log(total);
    const totalfordue = totalWithoutTransport || 0;
    // console.log(totalWithoutTransport);

    const afterDiscount = Boolean(totalfordue)
      ? totalfordue - (form.getFieldValue("discount") || 0) || 0
      : 0;

    const tDiscount = form.getFieldValue("discount") || 0;

    const totalWithTaxes =
      afterDiscount + calculateTaxAmount(afterDiscount, selectedTaxes);
    console.log(afterDiscount);
    const afterVatTaxAdded = totalWithTaxes;
    const paidAmount = parseFloat(form.getFieldValue("paidAmount")) || 0;
    const due =
      isNaN(afterVatTaxAdded) || isNaN(paidAmount)
        ? 0
        : afterVatTaxAdded + transportCharge - paidAmount;
    // console.log(afterVatTaxAdded);

    setSubTotal(subTotal);
    setTotal(total);
    setShowTotal(total);
    setAfterDiscount(afterDiscount);
    setAfterVatTaxAdded(totalWithTaxes);
    setDue(due);
    // console.log("Sub Total:", subTotal);
    // console.log("Total Tax:", totalTax);
    // console.log("Total Amount:", totalWithoutTransport + totalTax);

    // console.log(
    //   "Total + Taxes: ",
    //   fpriceTotal +
    //     selectedTaxes.reduce(
    //       (sum, tax) => (afterVatTaxAdded * tax.percentage) / 100,
    //       0
    //     )
    // );
    // const proTotal = afterVatTaxAdded - tDiscount;
    // const ProTax =
    //   proTotal +
    //   selectedTaxes.reduce((sum, tax) => (proTotal * tax.percentage) / 100, 0);
    const sTaxes = selectedTaxes.reduce(
      (sum, tax) => (fpriceTotal * tax.percentage) / 100,
      0
    );
    setSTaxes(sTaxes);
    // console.log("Tax Value: ", sTaxes);
    // console.log("Total Price: ", fpriceTotal);
    // console.log("Total Price After Tax ");
  };

  const tcharge = parseFloat(form.getFieldValue("transportCharge")) || 0;

  const stotal = subTotal + tcharge;

  const calculateTaxAmount = (totalAmount, taxes) => {
    console.log(totalAmount);
    return taxes.reduce((total, tax) => {
      const taxPercentage = parseFloat(tax.percentage) || 0;
      const taxAmount = (totalAmount * taxPercentage) / 100;
      return total + taxAmount;
    }, 0);
  };

  const pTotal =
    subTotal && subTotal.length > 0
      ? subTotal.reduce((total, value) => total + value, 0).toFixed(2)
      : "0.00";

  return (
    <Form
      form={form}
      className="w-full "
      name="dynamic_form_nest_item"
      onFinish={onFormSubmit}
      onFinishFailed={() => {
        setLoader(false);
      }}
      layout="vertical"
      size="large"
      autoComplete="off"
      initialValues={{
        paidAmount: 0,
        discount: 0,
        vatId: [],
        date: dayjs(),
      }}
    >
      <Products
        totalCalculator={totalCalculator}
        subTotal={subTotal}
        form={form}
        productList={productList}
        productLoading={productLoading}
      />
      <div className="flex gap-8 mt-10">
        <div className="w-1/2">
          <div className="flex gap-5 items-center">
            <div className="w-1/2 flex items-center gap-2">
              <Form.Item
                label="Supplier "
                name="supplierId"
                className="w-full"
                rules={[
                  {
                    required: true,
                    message: "Please Select a supplier!",
                  },
                ]}
              >
                <Select
                  className="w-full"
                  loading={!allSuppliers}
                  showSearch
                  placeholder="Select a supplier "
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {allSuppliers &&
                    allSuppliers.map((sup) => (
                      <Option key={sup.id} value={sup.id}>
                        {sup.name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              {/* <BigDrawer
                className='-mb-[46px]'
                title='Add New Supplier'
                btnTitle={"Supplier"}
                children={<AddSup drawer={true} />}
              /> */}
            </div>

            <Form.Item
              name="date"
              label="Date"
              className="w-1/2"
              required
              rules={[
                {
                  required: true,
                  message: "Please input Date!",
                },
              ]}
            >
              <DatePicker
                size="small"
                format={"YYYY-MM-DD"}
                className="date-picker"
                // disabledDate={disabledDate}
              />
            </Form.Item>
          </div>

          <div className="flex gap-5 items-center my-5 mb-5">
            <Form.Item
              label="Warehouse"
              name="warehouseId"
              className="w-full"
              rules={[
                {
                  required: true,
                  message: "Please select a warehouse!",
                },
              ]}
            >
              <Select
                className="w-full"
                loading={!allWarehouses}
                showSearch
                placeholder="Select a warehouse"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {allWarehouses &&
                  allWarehouses.map((warehouse) => (
                    <Option key={warehouse.id} value={warehouse.id}>
                      {warehouse.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Company"
              name="companyId"
              className="w-full"
              rules={[
                {
                  required: true,
                  message: "Please select a Company!",
                },
              ]}
            >
              <Select
                className="w-full"
                loading={!allCompanies}
                showSearch
                placeholder="Select a Company"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {allCompanies &&
                  allCompanies.map((company) => (
                    <Option key={company.id} value={company.id}>
                      {company.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>

          <div className="flex gap-5 items-center my-5 mb-5">
            <Form.Item className="w-1/2" name="ewayBill" label="Eway Bill">
              <Input className="w-full" placeholder="Eway Bill " />
            </Form.Item>

            <Form.Item
              className="w-1/2"
              name="transportCharge"
              label="Transport Charge"
            >
              <Input
                className="w-full"
                placeholder="Transport Charge"
                defaultValue={0}
                onChange={() => totalCalculator()}
              />
            </Form.Item>
          </div>
          <div className="flex gap-5 items-center my-3 mb-10">
            <Form.Item
              className="w-1/2"
              name="supplierMemoNo"
              label="Supplier Memo"
            >
              <Input className="w-full" placeholder="Memo no " />
            </Form.Item>

            <Form.Item className="w-1/2" name="note" label="Purchase Note">
              <Input className="w-full" placeholder="Note" />
            </Form.Item>
          </div>
          <div className="flex gap-5 items-center my-3 mb-10">
            <Form.Item
              className="w-1/2"
              name="invoiceNumber"
              label="Invoice Number"
            >
              <Input className="w-full" placeholder="InvoiceNumber" />
            </Form.Item>
          </div>
        </div>

        <div className="py-2  w-1/2">
          <div className="p-1 flex justify-between">
            <strong>Product Tax: </strong>
            <strong>{totalTaxo.toFixed(2)}</strong>
          </div>
          <div className="p-1 flex justify-between">
            <strong>Total: </strong>
            <strong>{showTotal.toFixed(2)}</strong>
          </div>

          <div className="flex justify-between items-center mb-2">
            <span className="">Tax: </span>
            <Form.Item className="mb-0 w-4/5 flex justify-end" name="vatId">
              <Select
                mode="multiple"
                className="w-72 "
                placeholder="Select Tax type"
                maxTagCount={5}
                showArrow
                size={"small"}
                loading={vatTaxLoading}
                onChange={() => totalCalculator()}
              >
                {vatTaxList?.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.title}
                    <span className="italic">@{item.percentage}%</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          {/* <div className="py-1 flex justify-between">
            <p>Tax on Purchase:</p>
            <strong>{sTaxes.toFixed(2)}</strong>
          </div> */}

          <div className="flex justify-between items-center py-2">
            <span className="">Discount: </span>
            <Form.Item
              className="mb-0"
              name="discount"
              rules={[
                {
                  validator: validateDiscount,
                },
              ]}
            >
              <InputNumber
                className="w-52"
                onChange={() => totalCalculator()}
                defaultValue={0}
                size="small"
              />
            </Form.Item>
          </div>
          {/* <div className="py-1 flex justify-between items-center">
            <span>After Tax & Discount: </span>
            <span>{afterVatTaxAdded.toFixed(2)}</span>
          </div> */}
          <div className="flex justify-between items-center mb-2">
            <span className="">Paid Amount: </span>
            <Form.Item
              className="mb-0"
              name="paidAmount"
              rules={[
                {
                  validator: validatePaidAmount,
                },
              ]}
            >
              <InputNumber
                className="w-52"
                onChange={() => totalCalculator()}
                defaultValue={0}
                size="small"
              />
            </Form.Item>
          </div>
          <div className="py-1 mb-4 flex justify-between">
            <strong>Due Amount:</strong>
            <strong>{due.toFixed(2)}</strong>
          </div>
          <Form.Item style={{ marginTop: "15px" }}>
            <Button
              block
              type="primary"
              htmlType="submit"
              loading={loader}
              onClick={() => {
                onClickLoading();
              }}
            >
              Purchase Product
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default AddPurch;
