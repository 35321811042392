import { Button, DatePicker, Form, Input, InputNumber, Select } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllCustomer } from "../../redux/rtk/features/customer/customerSlice";
import {
  loadProduct,
  loadProductSale,
} from "../../redux/rtk/features/product/productSlice";
import { addSale } from "../../redux/rtk/features/sale/saleSlice";
import Products from "./Products";
import "./sale.css";
import dayjs from "dayjs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loadAllSale } from "../../redux/rtk/features/sale/saleSlice";
import { loadAllStaff } from "../../redux/rtk/features/user/userSlice";
import { loadAllVatTax } from "../../redux/rtk/features/vatTax/vatTaxSlice";
import BigDrawer from "../Drawer/BigDrawer";
import AddCust from "../customer/addCust";
import getStaffId from "../../utils/getStaffId";

const AddSale = () => {
  const { Option } = Select;
  const [loader, setLoader] = useState(false);
  const [subTotal, setSubTotal] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [total, setTotal] = useState(0);
  const [afterDiscount, setAfterDiscount] = useState(0);
  const [afterVatTaxAdded, setAfterVatTaxAdded] = useState(0);
  const [payout, setPayout] = useState(0);
  const [payoutAmount, setPayoutAmount] = useState(0);
  console.log(payoutAmount);
  console.log(payout);
  const [due, setDue] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Form Function
  const [form] = Form.useForm();
  const { list: productList, loading: productLoading } = useSelector(
    (state) => state.products
  );
  console.log(productList);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  console.log(selectedCompanyId);
  const handleCompanyChange = (value) => {
    try {
      // console.log("Selected Company ID:", value);
      setSelectedCompanyId(value);
      setSelectedProductId(null);
      if (value) {
        form.resetFields([
          "date",
          "customerId",
          "userId",
          "transportCharge",
          "ewaybill",
          "mode",
          "remark",
          "address",
          "note",
          "paidAmount",
          "discount",
          "vatId",
        ]);
        setSubTotal([]);
        setTotal(0);
        setAfterDiscount(0);
        setAfterVatTaxAdded(0);
        setDue(0);
        productList = "";

        dispatch({ type: "products/clearProducts" });

        form.setFieldsValue({ saleInvoiceProduct: [] });
        console.log(value);

        dispatch(loadProduct({ companyId: selectedCompanyId }));
      }
    } catch (error) {
      console.error("Error handling company change:", error);
    }
  };

  const nproductList = productList?.getAllProduct || productList;
  console.log(nproductList);
  const [companies, setCompanies] = useState([]);
  const [warehouse, setWarehouse] = useState([]);
  // console.log(warehouse);
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(
          "http://82.112.227.185:5000/sale-invoice/companies?query=all"
        );

        if (response.data && response.data.length > 0) {
          setCompanies(response.data);
        }
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };
    fetchCompanies();
  }, []);
  // ...

  useEffect(() => {
    const fetchWarehouse = async () => {
      try {
        const response = await axios.get(
          `http://82.112.227.185:5000/sale-invoice/warehouse?productId=${selectedProductId}&key=no&query=search`
        );
        console.log(response.data);

        if (response.data) {
          setWarehouse(response.data);
        }
      } catch (error) {
        console.error("Error fetching warehouse:", error);
      }
    };

    if (selectedProductId) {
      fetchWarehouse();
    }
  }, [selectedProductId]);

  const allCustomer = useSelector((state) => state.customers.list);
  console.log(allCustomer);
  const staffId = getStaffId();
  const [userId, setUserId] = useState(staffId);
  const allStaff = useSelector((state) => state.users.list);
  const { list: vatTaxList, loading: vatTaxLoading } = useSelector(
    (state) => state.vatTax
  );
  useEffect(() => {
    dispatch(loadAllStaff({ status: "true" }));
    dispatch(loadAllCustomer({ page: 1, count: 5000 }));
    dispatch(loadAllVatTax());
    if (selectedCompanyId) {
      dispatch(loadProductSale({ companyId: selectedCompanyId }));
    }
  }, [dispatch, selectedCompanyId]);
  const onFormSubmit = async (values) => {
    try {
      const discountValue = form.getFieldValue("discount") || 0;
      // debugger;
      const mergedObject = values.saleInvoiceProduct.reduce(
        (accumulator, currentObject) => {
          const productId = currentObject.productId;
          if (!accumulator[productId]) {
            const { product, ...data } = currentObject;
            accumulator[productId] = { ...data, ...currentObject.product };
          } else {
            accumulator[productId].productQuantity +=
              currentObject.productQuantity;
          }
          return accumulator;
        },
        {}
      );
      const mergedArray = Object.values(mergedObject);
      const updatedProducts = mergedArray.map((product) => ({
        ...product,
        payoutAmount: payoutAmount, // Adjust this according to where you get the new field value from
      }));
      const data = {
        ...values,
        userId: userId,
        // payout: payout,
        saleInvoiceProduct: updatedProducts,
        warehouseId: values.warehouseId,
        date: values.date,
        company: selectedCompanyId,
        transportCharge: values.transportCharge,
        ewayBill: values.ewaybill,
        discount: discountValue,
      };
      
      const resp = await dispatch(addSale(data));
      if (resp.payload.message === "success") {
        form.resetFields();
        setLoader(false);
        dispatch(
          loadAllSale({
            page: 1,
            count: "",
            startdate: moment().format("YYYY-MM-DD"),
            enddate: moment().format("YYYY-MM-DD"),
            user: "",
          })
        );
        navigate(`/admin/sale/${resp.payload.createdInvoiceId}`);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.log(error.message);
      setLoader(false);
      toast.error("Error while sales");
    }
  };

  // validation handlers
  const validatePaidAmount = (_, value) => {
    if (value >= 0 && value <= afterVatTaxAdded.toFixed(2)) {
      return Promise.resolve();
    }
    return Promise.reject(
      `Paid amount must be gater than or equal ${afterVatTaxAdded.toFixed(2)}`
    );
  };
  const validateDiscount = (_, value) => {
    if (value >= 0 && value <= total) {
      return Promise.resolve();
    }
    return Promise.reject(`Discount must be gater than or equal ${total}`);
  };
  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day"); 
  };

  // total calculate
  const totalCalculator = () => {
    // debugger;
    const productArray = form.getFieldValue("saleInvoiceProduct");
    const transportCharge = form.getFieldValue("transportCharge") || 0;
    const subTotal =
      productArray?.reduce((subTotal, current) => {
        const quantity = current?.product?.productQuantity || 0;
        // console.log(quantity);
        setPayoutAmount(payout * quantity);
        const price = current?.product?.productSalePrice || 0;
        const vat = current?.product?.productVat || 0;
        const totalVat = (vat / 100) * price * quantity;

        return [
          ...subTotal,
          {
            subVat: current?.productVat || 0,
            subPrice: price * quantity + totalVat,
          },
        ];
      }, []) || [];

    setSubTotal(subTotal);
    const total =
      subTotal.reduce((total, current) => total + current.subPrice, 0) || 0;

    setTotal(total + transportCharge);

    const afterDiscount = Boolean(total)
      ? total + transportCharge - (form.getFieldValue("discount") || 0) || 0
      : 0;
    setAfterDiscount(afterDiscount);

    // vat tax calculate
    const vatFields = form.getFieldValue("vatId");
    const totalVatArray =
      vatFields?.map((id) => {
        return vatTaxList.find((item) => id === item.id)?.percentage;
      }) || [];
    const TotalTaxVatPercent = totalVatArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    const afterVatTaxAdded = afterDiscount + (TotalTaxVatPercent / 100) * total;
    setAfterVatTaxAdded(afterVatTaxAdded);

    //due count
    const due = Boolean(afterVatTaxAdded)
      ? afterVatTaxAdded - (form.getFieldValue("paidAmount") || 0) || 0
      : 0;
    setDue(due);
  };

  return (
    <Form
      form={form}
      name="dynamic_form_nest_item"
      onFinish={onFormSubmit}
      onFinishFailed={() => {
        setLoader(false);
      }}
      layout="vertical"
      size="large"
      autoComplete="off"
      initialValues={{
        paidAmount: 0,
        discount: 0,
        date: dayjs(),
        vatId: [],
        companyId: null,
        transportCharge: 0,
        ewaybill: "",
      }}
    >
      <div className="flex gap-5">
        <Form.Item
          label="Company"
          className="w-full"
          name="companyId"
          rules={[
            {
              required: true,
              message: "Please Select a Company!",
            },
          ]}
        >
          <Select
            className="w-full"
            showSearch
            placeholder="Select a company"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={handleCompanyChange}
          >
            {companies.map((company) => (
              <Option key={company.id} value={company.id}>
                {company.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Date"
          required
          className="w-1/2"
          name="date"
          rules={[
            {
              required: true,
              message: "Please input Date!",
            },
          ]}
        >
          <DatePicker
            style={{ marginBottom: "10px" }}
            label="date"
            size="small"
            defaultValue={dayjs()}
            format={"YYYY-MM-DD"}
            // disabledDate={disabledDate}
          />
        </Form.Item>
      </div>

      <Products
        form={form}
        totalCalculator={totalCalculator}
        subTotal={subTotal}
        productList={nproductList != undefined ? nproductList : []}
        productLoading={productLoading}
        payout={payout}
        setPayout={setPayout}
        payoutAmount={payoutAmount}
        setPayoutAmount={setPayoutAmount}
        selectedProductId={selectedProductId}
        setSelectedProductId={setSelectedProductId}
        selectedCompanyId={selectedCompanyId}
      />

      <div className="flex gap-5 my-5">
        <div className="w-1/2">
          <div className="flex gap-3 items-center">
            <Form.Item
              label="Customer"
              className="w-full"
              name="customerId"
              rules={[
                {
                  required: true,
                  message: "Please Select a Customer!",
                },
              ]}
            >
              <Select
                className="w-full"
                loading={!allCustomer}
                showSearch
                placeholder="Select a customer "
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {allCustomer &&
                  allCustomer?.getAllCustomer &&
                  allCustomer?.getAllCustomer?.map((sup) => (
                    <Option key={sup.id} value={sup.id}>
                    {`${sup.name}${sup.companyName ? ` (${sup.companyName})` : ''}`}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
            <BigDrawer
              className={"-mb-[46px]"}
              title={"new Customer"}
              btnTitle={"customer"}
              children={<AddCust drawer={true} />}
            />
          </div>

          <div className="flex gap-5">
            <Form.Item
              label="Date"
              required
              className="w-1/2"
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please input Date!",
                },
              ]}
            >
              <DatePicker
                style={{ marginBottom: "10px" }}
                label="date"
                size="small"
                defaultValue={dayjs()}
                format={"YYYY-MM-DD"}
                // disabledDate={disabledDate}
              />
            </Form.Item>

            <Form.Item
              className="w-1/2"
              label="Sales Person "
              name="userId"
              required
            >
              <Select
                className="w-full"
                loading={!allStaff}
                showSearch
                placeholder="Select sales person "
                optionFilterProp="children"
                onChange={(value) => setUserId(value)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
              >
                {allStaff &&
                  allStaff?.map((info) => (
                    <Option key={info.id} value={info.id}>
                      {info.username}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </div>
          <div className="flex gap-5">
            <Form.Item
              label="Transport Charge"
              className="w-1/2"
              name="transportCharge"
              rules={[
                {
                  type: "number",
                  min: 0,
                  message: "Transport charge must be a non-negative number",
                },
              ]}
            >
              <InputNumber
                className="w-full"
                size="small"
                defaultValue={0}
                onChange={() => totalCalculator()}
              />
            </Form.Item>

            <Form.Item
              label="E-way Bill"
              className="w-1/2"
              name="ewaybill"
              rules={[
                {
                  max: 255,
                  message: "E-way bill must be at most 255 characters",
                },
              ]}
            >
              <Input
                className="w-full"
                size="small"
                placeholder="Enter E-way bill"
                style={{ marginBottom: "10px" }}
              />
            </Form.Item>
          </div>
          <div className="flex gap-5">
            <Form.Item label="Payment Mode" className="w-1/2" name="mode">
              <Input
                className="w-full"
                size="small"
                placeholder="Enter Payment Mode"
                style={{ marginBottom: "10px" }}
              />
            </Form.Item>

            <Form.Item
              label="Remarks"
              className="w-1/2"
              name="remark"
              rules={[
                {
                  max: 255,
                  message: "Remarks must be at most 255 characters",
                },
              ]}
            >
              <Input
                className="w-full"
                size="small"
                placeholder="Enter Remarks"
                style={{ marginBottom: "10px" }}
              />
            </Form.Item>
          </div>

          <Form.Item className="" label="Shipping Address" name="address">
            <Input
              className=""
              placeholder="Enter shipping address"
              size={"small"}
              style={{ marginBottom: "10px" }}
            />
          </Form.Item>

          <Form.Item className="" label="Note" name="note">
            <Input
              className=""
              size={"small"}
              placeholder="Write sale Note"
              style={{ marginBottom: "10px" }}
              label="note"
            />
          </Form.Item>
        </div>
        <div className="w-1/2">
          {" "}
          <div className="py-2">
            <div className="p-1 flex justify-between">
              <strong>Total: </strong>
              <strong>{total.toFixed(2)} </strong>
            </div>
            <div className="flex justify-between items-center py-2">
              <span className="">Discount: </span>
              <Form.Item
                className="mb-0"
                name="discount"
                rules={[
                  {
                    validator: validateDiscount,
                  },
                ]}
              >
                <InputNumber
                  className="w-72"
                  size={"small"}
                  onChange={() => totalCalculator()}
                  defaultValue={0}
                />
              </Form.Item>
            </div>
            <div className="py-1 flex justify-between items-center mb-1">
              <span>After Discount: </span>
              <span>{afterDiscount.toFixed(2)}</span>
            </div>
            <div className="flex justify-between items-center mb-2">
              <span className="">Tax: </span>
              <Form.Item className="mb-0 w-4/5 flex justify-end" name="vatId">
                <Select
                  mode="multiple"
                  className="w-72 "
                  placeholder="Select Tax type"
                  maxTagCount={5}
                  showArrow
                  size={"small"}
                  loading={vatTaxLoading}
                  onChange={() => totalCalculator()}
                >
                  {vatTaxList?.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.title}
                      <span className="italic">@{item.percentage}%</span>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="py-1 flex justify-between items-center mb-1">
              <span>Total Payable: </span>
              <span>{afterVatTaxAdded.toFixed(2)}</span>
            </div>
            <div className="flex justify-between items-center mb-2">
              <span className="">Paid Amount: </span>
              <Form.Item
                className="mb-0"
                name="paidAmount"
                rules={[
                  {
                    validator: validatePaidAmount,
                  },
                ]}
              >
                <InputNumber
                  className="w-72"
                  onChange={() => totalCalculator()}
                  defaultValue={afterVatTaxAdded.toFixed(2)}
                  size={"small"}
                />
              </Form.Item>
            </div>
            <div className="py-1 mb-1 flex justify-between">
              <strong>Due Amount:</strong>
              <strong>{due.toFixed(2)}</strong>
            </div>
          </div>
          <Form.Item style={{ marginTop: "15px" }}>
            <Button
              disabled={loader}
              block
              type="primary"
              htmlType="submit"
              loading={loader}
            >
              Sale Product
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
};

export default AddSale;
