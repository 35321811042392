import React from "react";
import { Button, Card, Form, Input, Typography } from "antd";
import styles from "./AddCompany.module.css";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addNewCompany } from "../../redux/rtk/features/company/companySlice";

const AddCompany = ({ drawer }) => {
  const dispatch = useDispatch();
  const { Title } = Typography;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const formFields = [
    {
      label: "Name",
      name: "name",
      required: true,
      message: "Company Name is Required!",
      placeholder: "Company Name",
      validator: (_, value) => {
        if (!/^[a-zA-Z ]+$/.test(value)) {
          return Promise.reject("Invalid Company Name!");
        }
        return Promise.resolve();
      },
    },
    {
      label: "GSTIN",
      name: "gstIn",
      required: true,
      message: "GSTIN is Required!",
      placeholder: "GST Number",
      validator: (_, value) => {
        if (!/^[a-zA-Z0-9]+$/.test(value)) {
          return Promise.reject("Invalid GSTIN Number!");
        }
        if (value.length !== 15) {
          return Promise.reject("GSTIN Number must be 15 characters long!");
        }
        return Promise.resolve();
      },
    },

    {
      label: "CIN",
      name: "cin",
      required: true,
      message: "CIN is Required!",
      placeholder: "CIN Number",
      validator: (_, value) => {
        if (!/^[a-zA-Z0-9]+$/.test(value)) {
          return Promise.reject("Invalid CIN Number!");
        }
        return Promise.resolve();
      },
    },
    {
      label: "PAN",
      name: "pan",
      required: true,
      message: "PAN is Required!",
      placeholder: "PAN Number",
      validator: (_, value) => {
        if (!/^[a-zA-Z0-9]+$/.test(value)) {
          return Promise.reject("Invalid PAN Number!");
        }
        return Promise.resolve();
      },
    },
    {
      label: "MSME",
      name: "hsn",
      required: false,
      message: "MSME is Required!",
      placeholder: "MSME Number",
    },
    {
      label: "Email",
      name: "email",
      required: true,
      message: "Email Address is Required!",
      placeholder: "Email Address",
      validator: (_, value) => {
        // Use a regular expression to validate email addresses
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(value)) {
          return Promise.reject("Invalid Email Address!");
        }

        return Promise.resolve();
      },
    },
    {
      label: "Phone Number",
      name: "phone",
      required: true,
      message: "Phone Number is Required!",
      placeholder: "Phone Number",
      validator: (_, value) => {
        const phoneNumberRegex = /^\+?[0-9]{10,}$/;
        if (!phoneNumberRegex.test(value)) {
          return Promise.reject("Invalid Phone Number!");
        }
        return Promise.resolve();
      },
    },
    {
      label: "Address",
      name: "address",
      required: true,
      message: "Company Address is Required!",
      placeholder: "Company Address",
    },
    {
      label: "Billing Limit",
      name: "billLimit",
      required: true,
      message: "Billing Limit is Required!",
      placeholder: "200",
      type: "number",
      defaultValue: 0,
      validator: (_, value) => {
        if (!/^[0-9]+$/.test(value)) {
          return Promise.reject("Invalid Billing Limit!");
        }
        return Promise.resolve();
      },
      hidden: true,
    },
    {
      label: "Website",
      name: "website",
      required: true,
      message: "Please Input Company Website!",
      placeholder: "Website",
    },
    {
      label: "Tagline",
      name: "tagLine",
      required: true,
      message: "Please Input Company Tagline!",
      placeholder: "Tagline",
    },
    {
      label: "Logo",
      name: "logo",
      required: true,
      message: "Please Enter Logo URL!",
      placeholder: "Logo URL",
    },
    {
      label: "QR Code",
      name: "qrCode",
      required: true,
      message: "Please QR Code URL!",
      placeholder: "QR Code",
    },
  ];

  const onFinish = async (values) => {
    try {
      console.log(values, "values..");
      if (!values.billLimit) {
        values.billLimit = 0;
        form.setFieldsValue({ billLimit: 0 });
      }
      const body = { ...values, footer: "", };
      const resp = await dispatch(addNewCompany(body));
      console.log("Resp:", resp);

      if (resp.payload.message === "success") {
        setLoading(false);
        form.resetFields();
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    console.log("Failed:", errorInfo);
  };

  const onClick = () => {
    setLoading(true);
  };

  return (
    <>
      <Card bordered={false} className="criclebox h-full">
        <Title level={4} className="m-3 text-center">
          Add New Company
        </Title>
        <Form
          form={form}
          className=""
          name="basic"
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            billLimit: 0,
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {formFields.map((item) => {
            return (
              !item.hidden && (
                <Form.Item
                  key={item.name}
                  style={{ marginBottom: "10px" }}
                  label={item.label}
                  name={item.name}
                  rules={[
                    {
                      required: item.required,
                      message: item.message,
                    },
                    {
                      validator: item.validator,
                    },
                  ]}
                >
                  <Input
                    placeholder={item.placeholder}
                    type={item.type ? item.type : "text"}
                  />
                </Form.Item>
              )
            );
          })}
          <Form.Item
            label="Bank Details"
            name="bankDetails"
            rules={[{ required: true, message: "Bank Details are Required!" }]}
          >
            <Input.TextArea placeholder="Enter Bank Details" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            className={styles.addProdBrandBtnContainer}
          >
            <Button
              type="primary"
              htmlType="submit"
              shape="round w-100"
              loading={loading}
              onClick={onClick}
            >
              Add Company
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default AddCompany;