import { Alert, Button, Card, Col, Form, Input, Row, Typography } from "antd";

import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  addCustomer,
  loadAllCustomer,
} from "../../redux/rtk/features/customer/customerSlice";
import styles from "./AddCust.module.css";

const AddCust = ({ drawer }) => {
  const dispatch = useDispatch();
  const { Title } = Typography;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  //check the page url has customer on it or not
  const isAdmin = window.location.href.includes("admin");

  const onClick = () => {
    setLoading(true);
  };

  const onFinish = async (values) => {
    try {
      const resp = await dispatch(addCustomer(values));
      if (resp.payload?.message === "success") {
        setSuccess(true);
        form.resetFields();
        dispatch(loadAllCustomer({ page: 1, count: 10, status: true }));
        if (isAdmin !== true) {
          setTimeout(() => {
            window.location.href = "/customer/login";
            setSuccess(false);
            setLoading(false);
          }, 5000);
        } else {
          setSuccess(false);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    setLoading(false);
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      {/* <Row className="mr-top flex justify-center">
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={drawer ? 22 : 11}
          xl={drawer ? 22 : 11}
          className="rounded column-design "
        > */}
      <Card
        bordered={false}
        className={
          isAdmin !== true
            ? "border-solid border-2 border-black-300 mt-[5rem]"
            : ""
        }
      >
        {success && (
          <Alert
            message="We have sent you an email with password ."
            description="Please check your email and login to your account."
            type="success"
            showIcon
          />
        )}
        <Title level={4} className="text-center">
          Register Now
        </Title>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Company Name"
            name="companyName"
            rules={[
              {
                required: false,
                message: "Please input company name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Contact Name"
            name="name"
            rules={[
              {
                required: true,
                message: "Please input  name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Email"
            name="email"
            rules={[
              {
                // required: false,
                message: "Please input Email!",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Phone"
            name="phone"
            rules={[
              {
                required: true,
                message: "Please input customer Phone!",
              },
              {
                pattern: /^[0-9+]+$/,
                message:
                  "Please enter a valid phone number (only digits and '+' allowed).",
              },
              {
                min: 10,
                message: "Phone number must be at least 10 digits.",
              },
              {
                max: 13,
                message: "Invalid",
              },
              {
                validator: (rule, value) => {
                  // Disallowed phone numbers
                  const disallowedNumbers = [
                    "123456789",
                    "+123456789",
                    "+91123456789",
                    "987654321",
                  ];

                  if (disallowedNumbers.includes(value)) {
                    return Promise.reject("Invalid Number.");
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="CIN"
            name="cin"
            rules={[
              {
                required: false,
                message: "Please input CIN!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="PAN"
            name="pan"
            rules={[
              {
                required: false,
                message: "Please input PAN!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="MSME"
            name="hsn"
            rules={[
              {
                required: false,
                message: "Please input MSME!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="GSTIN"
            name="gstin"
            rules={[
              {
                required: false,
                message: "Please input GSTIN!",
              },
              {
                validator: (_, value) => {
                  if (!/^[a-zA-Z0-9]+$/.test(value)) {
                    return Promise.reject("Invalid GSTIN Number!");
                  }
                  if (value.length !== 15) {
                    return Promise.reject(
                      "GSTIN Number must be 15 characters long!"
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: "10px" }}
            label="Address"
            name="address"
            rules={[
              {
                required: false,
                message: "Please input Address!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item
                style={{ marginBottom: "10px" }}
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input  password!",
                  },
                ]}>
                <Input type="password" />
              </Form.Item>

               Customer due droped */}

          <Form.Item
            style={{ marginBottom: "10px" }}
            className={styles.addCustomerBtnContainer}
          >
            <Button
              onClick={onClick}
              loading={loading}
              type="primary"
              htmlType="submit"
              shape="round"
            >
              Register Now
            </Button>
          </Form.Item>
        </Form>
        {!isAdmin && (
          <Title className="mt-5 mb-5 text-center text-base">
            Already have an account? <Link to="/customer/login">Login Now</Link>
          </Title>
        )}
      </Card>
      {/* </Col> */}
      {/* // </Row> */}
    </Fragment>
  );
};

export default AddCust;
