import { Button, Form, Input, message, Upload } from "antd";
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import "./companiesDetails.css";
import { UploadOutlined } from "@ant-design/icons";
import { updateCompany } from "../../redux/rtk/features/company/companySlice";
import axios from "axios";
import { toast } from "react-toastify";

const UpdateCompaniesDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const singleCompany = useSelector((state) => state.company.singleCompany);

  useEffect(() => {}, [dispatch, id]);

  const onFinish = async (values) => {
    try {
      await dispatch(updateCompany({ id, ...values }));
      // console.log(id)
      message.success("Company updated successfully");
      navigate(`/admin/companiesDetails/${id}`);
    } catch (error) {
      message.error("Failed to update company");
      console.log(values)
    }
  };

  // const onFinish = async (values) => {
  //   console.log("Form Values:", values);

  //   try {
  //     const formData = new FormData();
  //     formData.append("name", values.name);
  //     formData.append("gstIn", values.gstIn);
  //     formData.append("cin", values.cin);
  //     formData.append("pan", values.pan);
  //     formData.append("hsn", values.hsn);
  //     formData.append("email", values.email);
  //     formData.append("phone", values.phone);
  //     formData.append("address", values.address);
  //     formData.append("website", values.website);
  //     formData.append("tagLine", values.tagLine);
  //     formData.append("bankDetails", values.bank);
  //     formData.append("billLimit", "0");
  //     formData.append("footer", "");

  //     if (values.logo?.fileList?.[0]) {
  //       formData.append("logo", values.logo.fileList[0].originFileObj);
  //     }
  //     if (values.qrCode?.fileList?.[0]) {
  //       formData.append("qrCode", values.qrCode.fileList[0].originFileObj);
  //     }

  //     console.log("FormData Entries:");
  //     for (let pair of formData.entries()) {
  //       console.log(pair[0], pair[1]);
  //     }

  //     const resp = await axios.put(`companies/${id}`, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });

  //     if (resp.data.message === "success") {
  //       toast.success("Company updated successfully");
  //       navigate(`/admin/companiesDetails/${id}`);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error.message);
  //     toast.error("Failed to update company");
  //   }
  // };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Fragment>
      <h1 className="font-bold text-2xl mb-4">Update Company</h1>
      <Form
        name="updateCompanyForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={singleCompany}
      >
        <Form.Item
          className="mb-2"
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input the company name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="GST No."
          className="mb-2"
          name="gstIn"
          rules={[
            {
              required: true,
              message: "Please input the GST No.!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="MSME"
          className="mb-2"
          name="hsn"
          rules={[
            {
              required: true,
              message: "Please input the MSME!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="CIN No."
          className="mb-2"
          name="cin"
          rules={[
            {
              required: true,
              message: "Please input the CIN No.!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="PAN No."
          className="mb-2"
          name="pan"
          rules={[
            {
              required: true,
              message: "Please input the PAN No.!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="mb-2"
          label="Phone"
          name="phone"
          rules={[
            {
              required: true,
              message: "Please input the phone number!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="mb-2"
          label="Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "Please enter a valid email!",
            },
            {
              required: true,
              message: "Please input the email!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Website"
          className="mb-2"
          name="website"
          rules={[
            {
              required: true,
              message: "Please input the Website!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item
          label="Bill Limit"
          className="mb-2"
          name="billLimit"
          rules={[
            {
              required: true,
              message: "Please input the Bill Limit!",
            },
          ]}
        >
          <Input />
        </Form.Item> */}

        <Form.Item
          label="Address"
          name="address"
          className="mb-2"
          rules={[
            {
              required: true,
              message: "Please input the Address!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Tagline"
          className="mb-2"
          name="tagLine"
          rules={[
            {
              required: true,
              message: "Please input the Tagline!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Logo"
          className="mb-2"
          name="logo"
          rules={[
            {
              required: true,
              message: "Please input Logo!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="QR Code"
          className="mb-2"
          name="qrCode"
          rules={[
            {
              required: true,
              message: "Please input QR!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Bank Details"
          name="bankDetails"
          rules={[{ required: true, message: "Bank Details are Required!" }]}
        >
          <Input.TextArea placeholder="Enter Bank Details" />
        </Form.Item>

        <Form.Item className="" label="">
          <Button className="mt-5" type="primary" htmlType="submit">
            Update
          </Button>
        </Form.Item>
      </Form>
    </Fragment>
  );
};

export default UpdateCompaniesDetails;
