import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import axios from "axios";

export default function ProductAdd({
  form,
  productList,
  productLoading,
  totalCalculator,
  subTotal,
  selectedCompanyId,
  payout,
  payoutAmount,
  setPayout,
  setPayoutAmount,
  // selectedProductId,
  // setSelectedProductId,
}) {
  console.log(setPayoutAmount);

  const [selectedProductId, setSelectedProductId] = useState("");
  const [warehouses, setWarehouses] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState({});
  const [selectedQuantity, setSelectedQuantity] = useState(0);
  const [productWarehouses, setProductWarehouses] = useState({});
  const [initialPrices, setInitialPrices] = useState({});

  // console.log(selectedProductId);
  useEffect(() => {
    setSelectedProductId("");
    // setSelectedWarehouse({});
  }, [selectedCompanyId]);

  // console.log(warehouses);
  useEffect(() => {
    const fetchWarehouse = async () => {
      if (!selectedProductId) return; 

      try {
        const response = await axios.get(
          `http://82.112.227.185:5000/sale-invoice/warehouse?productId=${selectedProductId}&key=no&query=all&companyId=${selectedCompanyId}`
        );

        if (response.data && Array.isArray(response.data.warehouses)) {
          setProductWarehouses((prev) => ({
            ...prev,
            [selectedProductId]: response.data.warehouses, 
          }));
        } else {
          console.error("Invalid data format for warehouses:", response.data);
        }
      } catch (error) {
        console.error("Error fetching warehouse:", error);
      }
    };

    fetchWarehouse();
  }, [selectedProductId]);

  const handleSetInitial = (product, serial) => {
    console.log(product);

    totalCalculator(serial);
    // console.log(product);
    const productArray = form.getFieldValue("saleInvoiceProduct");
    const findProduct = productList.find((pro) => pro.id === product);
    setSelectedProductId(findProduct?.productId);
    console.log(findProduct);

    if (findProduct?.product?.productQuantity === 0) {
      toast.warning("Product is out of stock");
    }
    setInitialPrices((prev) => ({
      ...prev,
      [serial]: findProduct?.product?.productSalePrice || 0,
    }));


    const selectedQuantity = form.getFieldValue([
      "saleInvoiceProduct",
      serial,
      "product",
      "productQuantity",
    ]);
    const selectedWarehouseObj = warehouses.find(
      (wh) => wh.id === selectedWarehouse
    );
    console.log(selectedWarehouseObj);

    // console.log(selectedQuantity);

    if (
      selectedWarehouseObj &&
      selectedQuantity > selectedWarehouseObj.quantity
    ) {
      console.error("Quantity exceeds available stock in the warehouse");
      return;
    }

    const newArray = productArray.map((item, index) => {
      if (index === serial) {
        return {
          ...item,
          // productId: findProduct.id,
          productId: findProduct?.product?.id,
          warehouseId: selectedWarehouse,
          productName: findProduct?.product?.name,
          product: {
            ...item.product,
            unitMeasurement: findProduct?.product?.unitMeasurement,
            productQuantity: selectedQuantity,
            productSalePrice: findProduct?.product?.productSalePrice,
            productVat: findProduct?.product?.productVat,
          },
        };
      }

      return item;
    });
    // console.log(findProduct.product.productVat);

    form.setFieldsValue({
      saleInvoiceProduct: newArray,
    });

    totalCalculator(serial);
  };

  const handleDelete = (name, index) => {
    return () => {
      form.remove(name);
      totalCalculator(index);
    };
  };

  // console.log(selectedWarehouse);

  return (
    <>
      <Row className="mt-6 mr-4">
        <Col span={1}>
          <div className="font-weight-bold md:text-base xxs:text-xs sm:text-sm">
            SL
          </div>
        </Col>
        <Col span={6}>
          <div className="font-weight-bold md:text-base xxs:text-xs sm:text-sm">
            Product
          </div>
        </Col>
        {/* <Col span={1}>
          <div className="font-weight-bold md:text-base xxs:text-xs sm:text-sm">
            U.M.
          </div>
        </Col> */}
        <Col span={4}>
          <div className="ml-2 font-weight-bold md:text-base xxs:text-xs sm:text-sm">
            Warehouse
          </div>
        </Col>
        <Col span={3}>
          <div className="ml-12 font-weight-bold md:text-base xxs:text-xs sm:text-sm">
            Quantity
          </div>
        </Col>
        <Col span={2}>
          <div className="ml-4 font-weight-bold md:text-base xxs:text-xs sm:text-sm">
            Sale Price
          </div>
        </Col>
        <Col span={2}>
          <div className="ml-4 font-weight-bold md:text-base xxs:text-xs sm:text-sm">
            Tax
          </div>
        </Col>
        <Col span={2}>
          <div className="ml-4 font-weight-bold md:text-base xxs:text-xs sm:text-sm">
            Payout
          </div>
        </Col>
        <Col span={2}>
          <div className="ml-5 font-weight-bold md:text-base xxs:text-xs sm:text-sm">
            Total
          </div>
        </Col>
        <Col span={1}>
          <div className="md:text-base xxs:text-xs sm:text-sm">Delete</div>
        </Col>
      </Row>

      <hr style={{ backgroundColor: "black", marginTop: "0.5rem" }} />

      <Form.List
        name="saleInvoiceProduct"
        rules={[
          {
            required: true,
            message: "Product is required",
          },
        ]}
      >
        {(fields, { add, remove }) => (
          <>
            <div className="max-h-[200px] overflow-y-auto overflow-x-hidden mt-2">
              {fields.map(({ key, name, ...restField }, index) => (
                <Row className="mt-2" gutter={[5]} key={key}>
                  <Col span={1}>{index + 1}</Col>
                  <Col span={6}>
                    <Form.Item
                      {...restField}
                      name={[name, "productName"]}
                      rules={[
                        {
                          required: true,
                          message: "Product is required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Product"
                        showSearch
                        loading={productLoading}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(product) => {
                          handleSetInitial(product, index);
                        }}
                      >
                        {Array.isArray(productList) ? (
                          productList.map((item) => (
                            <Select.Option key={item.id} value={item.id}>
                              {item?.product?.name || item?.name}
                            </Select.Option>
                          ))
                        ) : (
                          <Select.Option value={null}>
                            No products available
                          </Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item
                      {...restField}
                      name={[name, "warehouseId"]}
                      rules={[
                        { required: true, message: "Warehouse is required" },
                      ]}
                    >
                      <Select
                        placeholder="Select Warehouse"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        onChange={(value) => {
                          const products =
                            form.getFieldValue("saleInvoiceProduct") || [];
                          products[index] = {
                            ...products[index],
                            warehouseId: value,
                          };
                          form.setFieldsValue({ saleInvoiceProduct: products });
                        }}
                      >
                        {(
                          productWarehouses[
                            form.getFieldValue([
                              "saleInvoiceProduct",
                              index,
                              "productId",
                            ])
                          ] || []
                        ).map((wh) => (
                          <Select.Option key={wh.id} value={wh.id}>
                            {wh.name} ({wh.quantity})
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>


                  <Col span={2}>
                    <Form.Item
                      {...restField}
                      name={[name, "product", "productQuantity"]}
                      rules={[
                        {
                          required: true,
                          message: "Quantity is required",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const selectedProductId = getFieldValue([
                              "saleInvoiceProduct",
                              index,
                              "productId",
                            ]);

                            const selectedWarehouseId = getFieldValue([
                              "saleInvoiceProduct",
                              index,
                              "warehouseId",
                            ]);

                            const selectedWarehouseObj = productWarehouses[
                              selectedProductId
                            ]?.find((wh) => wh.id === selectedWarehouseId);

                            if (!selectedWarehouseObj) {
                              return Promise.reject(
                                "Please select a warehouse first."
                              );
                            }

                            if (value > selectedWarehouseObj.quantity) {
                              return Promise.reject(
                                `Quantity should be less than or equal to ${selectedWarehouseObj.quantity}`
                              );
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        size="small"
                        min={0}
                        placeholder="Quantity"
                        onChange={() => totalCalculator(index)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                  <Form.Item
                    {...restField}
                    name={[name, "product", "productSalePrice"]}
                    rules={[
                      {
                        required: true,
                        message: "Price is required",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const initialPrice = initialPrices[index];

                          if (initialPrice !== undefined && value < initialPrice) {
                            return Promise.reject(
                              new Error(`Price cannot be lower than ${initialPrice}`)
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <InputNumber
                      size="small"
                      style={{ width: "100%" }}
                      placeholder="0"
                      onChange={() => totalCalculator(index)}
                    />
                  </Form.Item>
                </Col>
                  <Col span={2}>
                    <Form.Item
                      {...restField}
                      name={[name, "product", "productVat"]}
                      rules={[
                        {
                          required: false,
                          message: "Product Vat is required",
                        },
                      ]}
                    >
                      <InputNumber
                        disabled
                        size="small"
                        style={{ width: "100%" }}
                        placeholder="0"
                        // disabled
                        // onChange={() => totalCalculator(index)}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={2}>
                    <Form.Item {...restField} name={[name, "payout"]}>
                      <InputNumber
                        style={{ width: "100%" }}
                        size={"small"}
                        placeholder="0"
                        value={payout}
                        onChange={(value) => setPayout(value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col hidden>
                    <Form.Item
                      hidden
                      {...restField}
                      name={[name, "payoutAmount"]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        size={"small"}
                        placeholder="Payout"
                        value={payout * selectedQuantity}
                        onChange={(value) => setPayoutAmount(value)}
                      />
                    </Form.Item>
                  </Col>

                  {/* <Col span={4}>
                    <div className="font-weight-bold md:text-base xxs:text-xs">
                      {productList?.productVat || 0}%
                    </div>
                  </Col> */}
                  <Col span={2}>
                    <div className="ml-2 font-weight-bold md:text-base xxs:text-xs">
                      {subTotal[index]?.subPrice?.toFixed(2) || 0}
                    </div>
                  </Col>
                  <Col span={1}>
                    <Form.Item>
                      <button
                        shape="circle"
                        className="flex justify-center items-center bg-red-600 text-white p-2 rounded-md"
                        onClick={() => {
                          remove(name);
                          totalCalculator(index);
                        }}
                      >
                        <DeleteOutlined className="" />
                      </button>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </div>
            <Form.Item style={{ marginTop: "20px" }}>
              <Button
                type="dashed"
                onClick={() => {
                  add();
                  totalCalculator(fields.length);
                }}
                block
                icon={<PlusOutlined />}
              >
                Add Product
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
}
